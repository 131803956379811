import * as React from "react";
import { CreateJobPage } from "../../components/pages/CreateJob/CreateJob";
import { PageProps } from "gatsby";
import { JobStyle } from "@headbot/library";
import { Redirect } from "@reach/router";
import { HeadbotRoute } from "../../services/Constants/HeadbotRoute";

const validJobStyles = new Set<string>(Object.values(JobStyle));

const isValidJobStyle = (jobStyle: string): jobStyle is JobStyle => {
    return validJobStyles.has(jobStyle);
};

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ params }) => {
    const jobStyle = params?.jobStyle ?? "";
    if (isValidJobStyle(jobStyle)) {
        return <CreateJobPage jobStyle={jobStyle} />;
    }
    return <Redirect from="*" to={HeadbotRoute.Home} noThrow />;
};
export default Page;
