import * as React from "react";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Constants } from "../../../../../services/Constants/Constants";
import { telemetry } from "../../../../../services/Telemetry/Telemetry";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { SecondaryButton } from "../../../../atoms/Button/SecondaryButton";
import { FileUploader, IFileInput } from "../../../../atoms/FileUploader/FileUploader";
import { P } from "../../../../atoms/P/P";
import { WhatPhotosNeeded } from "../../../Instructions/WhatPhotosNeeded";
import { CreateJobContext } from "../../CreateJobContext";
import { CenteredH1, Root, CenteredP } from "../Stages.styles";
import { TelemetryEvent } from "@headbot/library";

export const Stage2: React.FC = () => {
    const [t] = useTranslation();
    const { onBack, onContinue, images, setImages } = useContext(CreateJobContext);

    const onFilesLoaded = useCallback(
        (files: Array<IFileInput>) => {
            setImages(files);
        },
        [setImages]
    );
    const imageCount = images.length;
    const isContinueDisabled = imageCount < Constants.MinimumRequiredImages;
    const selectedTextLocale =
        imageCount > 1
            ? t("createJobPage.stage2.totalSelectedPlural", { imageCount })
            : t("createJobPage.stage2.totalSelectedSingular", { imageCount });
    useEffect(() => {
        telemetry.Log(TelemetryEvent.CreateJobStage2);
    }, []);

    return (
        <>
            <Root>
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 2 })}</CenteredP>
                <SecondaryButton onClick={onBack} type="button">
                    {t("createJobPage.back")}
                </SecondaryButton>
                <CenteredH1>{t("createJobPage.stage2.uploadPhotos")}</CenteredH1>
                <WhatPhotosNeeded />
                <FileUploader
                    label={t("createJobPage.stage2.uploadFileLabel")}
                    onFilesLoaded={onFilesLoaded}
                    multiple={true}
                    accept="image/*"
                />
                <P>{selectedTextLocale}</P>
                <PrimaryButton onClick={onContinue} type="button" disabled={isContinueDisabled}>
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </Root>
        </>
    );
};
