import { StaticImage } from "gatsby-plugin-image";
import { t } from "i18next";
import * as React from "react";
import { H4 } from "../../../atoms/H4/H4";
import { P } from "../../../atoms/P/P";
import { ExamplesDescriptionRoot, PaddedParagraph, PhotoExample, PhotoExamplesRoot, PhotoSection } from "../FAQ.styles";

export const WhatToExpectBuff: React.FC = () => {
    return (
        <>
            <PaddedParagraph>{t("buffPage.faq.whatToExpectAnswer")}</PaddedParagraph>
            <PhotoSection>
                <ExamplesDescriptionRoot>
                    <H4>{t("buffPage.faq.goodPhotos")}</H4>
                    <P>{t("buffPage.faq.goodPhotosDescription")}</P>
                </ExamplesDescriptionRoot>
                <PhotoExamplesRoot>
                    <PhotoExample>
                        <StaticImage
                            src="./goodOutput1.jpg"
                            alt={t("buffPage.faq.goodAlt1")}
                            width={128}
                            height={192}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </PhotoExample>
                    <PhotoExample>
                        <StaticImage
                            src="./goodOutput2.jpg"
                            alt={t("buffPage.faq.goodAlt2")}
                            width={128}
                            height={192}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </PhotoExample>
                    <PhotoExample>
                        <StaticImage
                            src="./goodOutput3.jpg"
                            alt={t("buffPage.faq.goodAlt3")}
                            width={128}
                            height={192}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </PhotoExample>
                </PhotoExamplesRoot>
            </PhotoSection>
            <PhotoSection>
                <ExamplesDescriptionRoot>
                    <H4>{t("buffPage.faq.weirdPhotos")}</H4>
                    <P>{t("buffPage.faq.weirdPhotosDescription")}</P>
                </ExamplesDescriptionRoot>
                <PhotoExamplesRoot>
                    <PhotoExample>
                        <StaticImage
                            src="./weird1.jpg"
                            alt={t("buffPage.faq.weirdAlt1")}
                            width={128}
                            height={192}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </PhotoExample>
                    <PhotoExample>
                        <StaticImage
                            src="./weird2.jpg"
                            alt={t("buffPage.faq.weirdAlt2")}
                            width={128}
                            height={192}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </PhotoExample>
                    <PhotoExample>
                        <StaticImage
                            src="./weird3.jpg"
                            alt={t("buffPage.faq.weirdAlt3")}
                            width={128}
                            height={192}
                            imgStyle={{ borderRadius: "4%" }}
                        />
                    </PhotoExample>
                </PhotoExamplesRoot>
            </PhotoSection>
        </>
    );
};
