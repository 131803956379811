import { ProductID, TelemetryEvent } from "@headbot/library";
import { IconDiscountCheckFilled } from "@tabler/icons-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Button/PrimaryButton";
import { Color } from "../../layout/GlobalStyles/Color";
import { Prices } from "../../molecules/CreateJob/Stages/Stage4/Prices";
import { Feature, FeatureList, FeatureText, Name, Package, PackageList, Price } from "./PackageSelector.styles";
import { telemetry } from "../../../services/Telemetry/Telemetry";

interface IProps {
    readonly onSelectProduct: (productID: ProductID) => void;
    readonly selectedProductID: string | null;
    readonly packages: Array<IProduct>;
}

export interface IProduct {
    readonly productID: ProductID;
    readonly name: string;
    readonly code?: string;
    readonly features: Array<string>;
}

export const PackageSelector: React.FC<IProps> = ({ onSelectProduct, selectedProductID, packages }) => {
    const [t] = useTranslation();

    const packagesList = packages.map((p) => {
        const onSelect = async () => {
            onSelectProduct(p.productID);
            await telemetry.Log(TelemetryEvent.ProductSelected, p.productID);
        };
        const featuresList = p.features.map((f) => (
            <Feature key={f}>
                <IconDiscountCheckFilled size={28} strokeWidth={2} color={Color.Green100} />
                <FeatureText>{f}</FeatureText>
            </Feature>
        ));
        const isProductSelected = selectedProductID === p.productID;
        const disabled = isProductSelected;
        const shouldMinimiseMobile = selectedProductID !== null && isProductSelected === false;
        return (
            <Package isSelected={isProductSelected} key={p.name} shouldMinimiseMobile={shouldMinimiseMobile}>
                <Name>{p.name}</Name>
                <Price>
                    <Prices productID={p.productID} discountRate={0} showTextOnly={true} />
                </Price>
                <FeatureList>{featuresList}</FeatureList>
                <PrimaryButton onClick={onSelect} type="button" disabled={disabled}>
                    {t("createJobPage.stage4.select")}
                </PrimaryButton>
            </Package>
        );
    });
    return <PackageList>{packagesList}</PackageList>;
};
