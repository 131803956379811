import styled from "styled-components";
import { H3 } from "../../atoms/H3/H3";
import { P } from "../../atoms/P/P";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../layout/GlobalStyles/Viewport";

export const Root = styled.div`
    display: block;
    width: 100%;
`;
export const ContentRoot = styled.div`
    display: block;
    max-width: ${Dimensions.MaxContentWidth}px;
    margin: 0px auto;
`;
export const Content = styled.div`
    width: 100%;
    display: block;
`;
export const TextRoot = styled.div`
    margin: ${Dimensions.GridUnit * 1}px;
`;

export const PhotoSection = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    @media screen and (max-width: ${Viewport.Medium}px) {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }
`;

export const PhotoExamplesRoot = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

export const PhotoExample = styled.div`
    margin: ${Dimensions.GridUnit * 1}px;
`;

export const QuestionTitle = styled(H3)`
    text-align: center;
    padding-bottom: ${Dimensions.GridUnit * 2}px;
    @media screen and (max-width: ${Viewport.Medium}px) {
        text-align: left;
        padding-left: ${Dimensions.GridUnit * 2}px;
    }
`;

export const PaddedParagraph = styled(P)`
    padding-top: ${Dimensions.GridUnit * 2}px;
`;

export const PaddedTitle = styled(H3)`
    padding-top: ${Dimensions.GridUnit * 2}px;
`;

export const ExamplesDescriptionRoot = styled.div`
    display: flex;
    max-width: 250px;
    flex-direction: column;
    justify-content: center;
    margin: 0px auto;
    @media screen and (max-width: ${Viewport.Medium}px) {
        max-width: auto;
        justify-content: flex-start;
        margin: inherit;
        padding-left: ${Dimensions.GridUnit * 1}px;
        padding-top: ${Dimensions.GridUnit * 1}px;
        padding-bottom: ${Dimensions.GridUnit * 1}px;
    }
`;
