import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";
import { ProductID } from "@headbot/library";

interface IResponse {
    readonly price: number;
}

const getProductPrice = async (config: AxiosRequestConfig, productID: ProductID) => {
    const uri = `${Constants.ApiRoot}/product/${productID}/price`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useProductPrice = (productID: ProductID) => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Price, productID], () => getProductPrice(config, productID));
};
