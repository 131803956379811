import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { WhatToExpectBuff } from "../../../Instructions/WhatToExpectBuff/WhatToExpectBuff";
import { CreateJobContext } from "../../CreateJobContext";
import { Root, CenteredP, CenteredH1 } from "../Stages.styles";
import { JobStyle } from "@headbot/library";
import { WhatToExpectAnime } from "../../../Instructions/WhatToExpectAnime/WhatToExpectAnime";

export const Stage1: React.FC = () => {
    const [t] = useTranslation();
    const { onContinue, style } = useContext(CreateJobContext);
    const whatToExpect = style === JobStyle.Buff ? <WhatToExpectBuff /> : <WhatToExpectAnime />;
    return (
        <>
            <Root>
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 1 })}</CenteredP>
                <CenteredH1>{t("buffPage.faq.whatToExpect")}</CenteredH1>
                {whatToExpect}
                <PrimaryButton onClick={onContinue} type="button">
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </Root>
        </>
    );
};
