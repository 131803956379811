import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { IFileInput } from "../../../components/atoms/FileUploader/FileUploader";
import { AlertsContext, AlertType, IAlert } from "../../../components/molecules/Alerts/AlertsContext";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { telemetry } from "../../../services/Telemetry/Telemetry";
import { QueryCacheKey } from "../QueryCacheKey";
import { TelemetryEvent } from "@headbot/library";

interface IResponse {}

interface IMutationParams {
    readonly file: IFileInput;
    readonly guestJobId: string;
}

const getMutationQuery = () => {
    const postRequest = async (params: IMutationParams): Promise<IResponse> => {
        const { file, guestJobId } = params;
        const config = getAxiosRequestConfig(null, { type: "multipart/form-data" });
        const { data } = file;
        const bodyFormData = new FormData();
        bodyFormData.append("data", data, file.name);
        const request = axios.post(`${Constants.ApiRoot}/guestJob/${guestJobId}/file`, bodyFormData, config);

        const { data: response } = await request;
        return response;
    };
    return postRequest;
};

export const usePostGuestJobFile = (onSuccess: (response: IResponse) => void) => {
    const { pushAlert } = useContext(AlertsContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(), {
        onMutate: () => {
            telemetry.Log(TelemetryEvent.UploadGuestJobFileInit, null);
        },
        onSuccess: (data) => {
            telemetry.Log(TelemetryEvent.UploadGuestJobFileSuccess, null);
            queryClient.invalidateQueries([QueryCacheKey.GuestJob]);
            onSuccess(data);
        },
        onError: (error, _variables, _context) => {
            const e = error as AxiosError<any>;
            const submitError = JSON.stringify(e.response?.data?.error);
            const alert: IAlert = {
                type: AlertType.Error,
                message: submitError,
                timeout: Constants.AlertModalTimeoutMs,
            };
            pushAlert?.(alert);
            telemetry.Log(TelemetryEvent.UploadGuestJobFileError, submitError);
        },
    });
    return { mutation };
};
