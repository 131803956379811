import * as React from "react";
import { ChangeEvent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePostGuestJob } from "../../../../../hooks/api/job/usePostGuestJob";
import { usePostGuestJobFile } from "../../../../../hooks/api/job/usePostGuestJobFile";
import { PrimaryButton } from "../../../../atoms/Button/PrimaryButton";
import { SecondaryButton } from "../../../../atoms/Button/SecondaryButton";
import { RadioButtonGroup } from "../../../../atoms/RadioButtonGroup/RadioButtonGroup";
import { IRadioButtonOption } from "../../../../atoms/RadioButtonGroup/ReadioButtonGroup.types";
import { Spinner } from "../../../../atoms/Spinner/Spinner";
import { CreateJobContext } from "../../CreateJobContext";
import { CenteredH1, CenteredP, Root } from "../Stages.styles";
import { PromiseFunction, executePromisesSequentially } from "../../../../../functions/executePromisesSequentially";

export const Stage3: React.FC = () => {
    const [t] = useTranslation();
    const { onBack, gender, setGender, onContinue, images, setGuestJobId, style } = useContext(CreateJobContext);
    const { mutation: postGuestJobFile } = usePostGuestJobFile(() => {});
    const { mutation: postGuestJob } = usePostGuestJob(() => {});

    const [currentFileCount, setCurrentFileCount] = useState(1);

    const options: Array<IRadioButtonOption> = React.useMemo(
        () => [
            { key: "male", label: t("jobDetailsPage.male") },
            { key: "female", label: t("jobDetailsPage.female") },
            { key: "unspecified", label: t("jobDetailsPage.unspecified") },
        ],
        [gender, t]
    );

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setGender(e.target.value);
        },
        [setGender]
    );
    const onContinueClicked = useCallback(async () => {
        const { guestJobId } = await postGuestJob.mutateAsync({ gender, style });

        const fileUploadPromises: Array<PromiseFunction> = [];
        let count = 1;
        for (const file of images) {
            const p = () =>
                new Promise<void>(async (resolve) => {
                    await postGuestJobFile.mutateAsync({ guestJobId, file });
                    setCurrentFileCount(count++);
                    resolve();
                });
            fileUploadPromises.push(p);
        }
        await executePromisesSequentially(fileUploadPromises);
        onContinue();
        setGuestJobId(guestJobId);
    }, [images, setCurrentFileCount, gender, style]);
    const progressText = t("createJobPage.stage3.uploadingFile", { totalFiles: images.length, fileNumber: currentFileCount });
    if (postGuestJobFile.isLoading === true) {
        return <Spinner label={progressText} />;
    }

    return (
        <>
            <Root>
                <CenteredP>{t("createJobPage.stepProgress", { currentStep: 3 })}</CenteredP>
                <SecondaryButton onClick={onBack} type="button">
                    {t("createJobPage.back")}
                </SecondaryButton>
                <CenteredH1>{t("createJobPage.stage3.selectGender")}</CenteredH1>
                <RadioButtonGroup options={options} label={t("jobDetailsPage.selectGender")} onChange={onChange} selectedKey={gender} />
                <PrimaryButton onClick={onContinueClicked} type="button">
                    {t("createJobPage.continue")}
                </PrimaryButton>
            </Root>
        </>
    );
};
