import styled from "styled-components";
import { Color } from "../../layout/GlobalStyles/Color";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../layout/GlobalStyles/Viewport";

interface IPackageProps {
    readonly isSelected: boolean;
    readonly shouldMinimiseMobile: boolean;
}

export const Package = styled.li<IPackageProps>`
    width: 250px;
    min-height: ${({ shouldMinimiseMobile }) => (shouldMinimiseMobile ? 0 : 300)}px;
    background: ${Color.Neutral10};
    border: 3px solid ${({ isSelected }) => (isSelected ? Color.Neutral60 : Color.Neutral10)};
    border-radius: ${Dimensions.GridUnit}px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: ${Dimensions.GridUnit}px;
    box-sizing: border-box;
    @media (max-width: ${Viewport.Medium}px) {
        width: 100%;
        margin-bottom: ${Dimensions.GridUnit * 2}px;
        height: ${({ shouldMinimiseMobile }) => (shouldMinimiseMobile ? "200px" : "auto")};
        overflow: hidden;
    }
`;
export const Name = styled.h2`
    display: block;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`;

export const Price = styled.span`
    font-size: ${Dimensions.GridUnit * 3}px;
    text-align: center;
`;

export const PackageList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-bottom: ${Dimensions.GridUnit * 2}px;
    @media (max-width: ${Viewport.Medium}px) {
        flex-direction: column;
    }
`;

export const FeatureList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const Feature = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FeatureText = styled.li`
    padding-left: ${Dimensions.GridUnit}px;
`;
