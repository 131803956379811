import styled from "styled-components";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../../layout/GlobalStyles/Viewport";
import { H3 } from "../../../atoms/H3/H3";
import { H1 } from "../../../atoms/H1/H1";
import { H2 } from "../../../atoms/H2/H2";
import { P } from "../../../atoms/P/P";

export const Root = styled.div`
    display: block;
    max-width: ${Dimensions.MaxContentWidth}px;
    margin: 0px auto;
    @media screen and (max-width: ${Viewport.Large}px) {
        box-sizing: border-box;
        padding-left: ${Dimensions.GridUnit * 2}px;
        padding-right: ${Dimensions.GridUnit * 2}px;
    }
`;

export const CenteredH3 = styled(H3)`
    text-align: center;
`;

export const CenteredH1 = styled(H1)`
    text-align: center;
`;

export const CenteredH2 = styled(H2)`
    text-align: center;
`;

export const CenteredP = styled(P)`
    text-align: center;
`;

export const AnswerContainer = styled.div`
    padding-top: ${Dimensions.GridUnit * 2}px;
    padding-bottom: ${Dimensions.GridUnit * 2}px;
`;

export const PriceContainer = styled.div`
    background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(213, 178, 211, 1) 0%, rgba(148, 187, 233, 1) 100%);
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${Dimensions.GridUnit * 2}px;
`;

export const PricesContainer = styled.div`
    padding-top: ${Dimensions.GridUnit * 2}px;
    padding-bottom: ${Dimensions.GridUnit * 4}px;
`;
