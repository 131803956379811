import { Color } from "./../../layout/GlobalStyles/Color";
import styled from "styled-components";
import { Font } from "../../layout/GlobalStyles/Font";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";

export const UploaderContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 256px;
    height: 50px;
    background-color: ${Color.Primary100};
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
    color: inherit;
    transition: color 150ms ease;
    box-shadow: 0px 0px 8px ${Color.Primary50};
    :hover {
        opacity: 80%;
        background-color: ${Color.Primary110};
    }

    :active {
        background-color: ${Color.D};
    }
    margin-top: ${Dimensions.GridUnit * 3}px;
    margin-bottom: ${Dimensions.GridUnit * 3}px;
`;

export const FormField = styled.input`
    cursor: pointer;
    width: 256px;
    z-index: 1;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 64px;
`;

export const TextBox = styled.div`
    color: ${Color.E};
    font-size: 14px;
`;

export const IconWrapper = styled.div`
    color: ${Color.White100};
    font-family: ${Font.Poppins};
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
`;
