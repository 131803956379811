import { StaticImage } from "gatsby-plugin-image";
import { t } from "i18next";
import * as React from "react";
import { H4 } from "../../atoms/H4/H4";
import { P } from "../../atoms/P/P";
import { Content, ExamplesDescriptionRoot, PaddedParagraph, PhotoExample, PhotoExamplesRoot, PhotoSection } from "./FAQ.styles";

export const WhatPhotosNeeded: React.FC = () => {
    return (
        <>
            <Content>
                <PhotoSection>
                    <ExamplesDescriptionRoot>
                        <H4>{t("buffPage.howTo.goodPhotos")}</H4>
                        <PaddedParagraph>{t("buffPage.howTo.goodPhotosDescription")}</PaddedParagraph>
                    </ExamplesDescriptionRoot>
                    <PhotoExamplesRoot>
                        <PhotoExample>
                            <StaticImage src="./good1.jpg" alt="Some alt" width={128} height={192} imgStyle={{ borderRadius: "4%" }} />
                        </PhotoExample>
                        <PhotoExample>
                            <StaticImage src="./good2.jpg" alt="Some alt" width={128} height={192} imgStyle={{ borderRadius: "4%" }} />
                        </PhotoExample>
                        <PhotoExample>
                            <StaticImage src="./good3.jpg" alt="Some alt" width={128} height={192} imgStyle={{ borderRadius: "4%" }} />
                        </PhotoExample>
                    </PhotoExamplesRoot>
                </PhotoSection>
                <PhotoSection>
                    <ExamplesDescriptionRoot>
                        <H4>{t("buffPage.howTo.badPhotos")}</H4>
                        <P>{t("buffPage.howTo.badPhotosDescription")}</P>
                    </ExamplesDescriptionRoot>
                    <PhotoExamplesRoot>
                        <PhotoExample>
                            <StaticImage src="./bad1.jpg" alt="Some alt" width={128} height={192} imgStyle={{ borderRadius: "4%" }} />
                        </PhotoExample>
                        <PhotoExample>
                            <StaticImage src="./bad2.jpg" alt="Some alt" width={128} height={192} imgStyle={{ borderRadius: "4%" }} />
                        </PhotoExample>
                        <PhotoExample>
                            <StaticImage src="./bad3.jpg" alt="Some alt" width={128} height={192} imgStyle={{ borderRadius: "4%" }} />
                        </PhotoExample>
                    </PhotoExamplesRoot>
                </PhotoSection>
            </Content>
        </>
    );
};
