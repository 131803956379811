import * as React from "react";
import { IconDiscountCheckFilled } from "@tabler/icons-react";
import { PriceContainer } from "../Stages.styles";
import { Color } from "../../../../layout/GlobalStyles/Color";
import { BannerRoot, Price, UniqueImages } from "./Stage4.styles";
import { useTranslation } from "react-i18next";
import { useProductPrice } from "../../../../../hooks/api/product/useProductPrice";
import { Spinner } from "../../../../atoms/Spinner/Spinner";
import { BasePrice } from "./BasePrice";
import { ProductID } from "@headbot/library";

interface IProps {
    readonly productID: ProductID;
    readonly discountRate: number;
    readonly showTextOnly?: boolean;
}

export const Prices: React.FC<IProps> = ({ discountRate, productID, showTextOnly }) => {
    const [t] = useTranslation();
    const price = useProductPrice(productID);
    if (price.isLoading) {
        return <Spinner />;
    }
    const basePrice = price.data?.price ?? 0;
    const discountedPrice = basePrice * (1 - discountRate);
    const isDiscounted = discountRate > 0;
    if (showTextOnly) {
        return <span>{t("createJobPage.stage4.price", { price: discountedPrice.toFixed(2) })}</span>;
    }

    return (
        <PriceContainer>
            <IconDiscountCheckFilled size={28} strokeWidth={2} color={Color.Green100} />
            <UniqueImages>{t("createJobPage.stage4.valueProp")}</UniqueImages>
            <BannerRoot>
                <BasePrice strikethrough={isDiscounted}>{t("createJobPage.stage4.price", { price: basePrice.toFixed(2) })}</BasePrice>
                {isDiscounted && <Price>{t("createJobPage.stage4.price", { price: discountedPrice.toFixed(2) })}</Price>}
            </BannerRoot>
        </PriceContainer>
    );
};
